<template>
    <div class="new_content">
        <div class="search_box flex_sta_cen">   
            <div style="margin-right:16px">
                所属物料：
                <Select filterable  transfer  v-model="searchData.material_id" style="width:200px" placeholder="请选择所属物料" clearable @on-change="seachListChange">
                    <Option v-for="item in seachListArr" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </div>   
            <div style="margin-right:16px">
                标题：                
                <Input v-model="searchData.keywords" placeholder="请输入标题(回车搜索)" @on-enter="goSearch()" style="width: 200px"></Input>  
            </div>     
                  
            <!-- <div style="margin-right:16px">
                搜索叁：
                <DatePicker @on-change="changeTime" v-model="timeVal" type="daterange" placement="bottom-end" placeholder="请选择搜索叁" style="width: 200px"></DatePicker>           
            </div> -->
            <Button @click="goSearch()" style="background:#002FA7;color:#fff;border-color:#002FA7">搜索</Button>
            <!-- <Button @click="goExport()" style="background:#002FA7;color:#fff;border-color:#002FA7;margin-left:12px">导出</Button>             -->
        </div>    
        <div class="" >
            <Button @click="goAddData()" style="background:#002FA7;color:#fff;border-color:#002FA7">{{ addButtonText }}{{ pageTheme }}</Button>
        </div>
        <div class="cont_tab">
            <Table :columns="columnsTable" :data="dataTable" :loading="loadingTable" @on-selection-change="chooseData">
                <template slot-scope="{ index }" slot="index">
                    <strong>{{ index+1 }}</strong>
                </template>                            
                <template slot-scope="{ row }" slot="slotOne">                
                    自定义域壹
                </template>  
                <template slot-scope="{ row }" slot="slotTwo">                
                    自定义域贰
                </template>   
                <template slot-scope="{ row }" slot="tableTable">
                    <div class="flex_cen_cen">
                        <div class="tab_tab_text" @click="goShowTable(row)">{{row.enter_count}}</div>
                    </div>                
                </template>   
                <template slot-scope="{ row }" slot="tableSwitch">                
                    <i-switch   :true-value="1" :false-value="0" size="large" @on-change="changeSwitch" v-model="row.tableSwitch">
                        <span slot="open">开启</span>
                        <span slot="close">关闭</span>
                    </i-switch>
                </template>      
                       
                <template slot-scope="{ row }" slot="material"> 
                    <div>{{row.material?row.material.name:''}}</div>
                </template>    
                <template slot-scope="{ row }" slot="time"> 
                    <div>{{ row.start_time }} ~ {{ row.end_time }}</div>
                </template>  
                
                <template slot-scope="{ row }" slot="tablePhoto"> 
                    <!-- {{(imageConfig+'/'+row.qr_code)}} -->
                    <div class="table_img_box shubiao" @click="goSee((imageConfig+'/'+row.qr_code))">                        
                        <img :src="(imageConfig+'/'+row.qr_code)"/>
                    </div>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <Button @click="goEdit(row)" style="color:#002FA7;background: unset;" type="text">修改</Button>
                    <Button @click="active_delete(row)" style="color:red;background: unset;" type="text">删除</Button>
                    <Button @click="active_export(row)" style="color:green;background: unset;" type="text">下载</Button>
                    <Button @click="recommend_export(row)" style="color:green;background: unset;" type="text">总价筛选</Button>
                    <Button @click="unit_recommend_export(row)" style="color:green;background: unset;" type="text">单价筛选</Button>
                </template>
            </Table>
            <br />
            <Page :total="total" :current="pageForm.page" @on-change="pageChange" show-total show-elevator style="text-align:right" />   
        </div>
        <Modal v-model="showAddModal" width="600" :mask-closable="false">
            <p slot="header" style="text-align:center">
                <span>{{ modalType == 'add' ? ( '新增' + pageTheme ) : ( '修改' + pageTheme ) }}</span>
            </p>
            <div class="">
                <div class="form_box" style="padding-bottom:80px">
                    <div class="form_item_box" >
                        <div class="form_label">标题:</div>
                        <div class="form_conter" >
                            <Input v-model="formData.name" style="width:300px" placeholder="请输入标题"></Input>
                        </div>
                    </div>    
                   
                    <div class="form_item_box">
                        <div class="form_label">报价物料:</div>
                        <div class="form_conter">
                            <Select filterable  transfer  v-model="formData.material_id" style="width:300px" placeholder="请选择报价物料" clearable  >
                                <Option v-for="item in seachListArr" :value="item.id" :key="item.id">{{ item.name }}</Option>
                            </Select>
                        </div>
                    </div>
                  
                    <div class="form_item_box">
                        <div class="form_label">起止时间:</div>
                        <div class="form_conter">                            
                            <DatePicker @on-change="changeModalTime" v-model="timeModalVal" type="datetimerange" placement="bottom-end" placeholder="请选择起止时间" style="width: 300px"></DatePicker> 
                        </div>
                    </div>
                                    
                </div>
            </div>
            <div slot="footer">
                <Button @click="close_modal()" >取消</Button>
                <Button @click="active_create()" style="background:#002FA7;color:#fff;border-color:#002FA7">确认</Button>                
            </div>
        </Modal>
        
        <Modal v-model="showTableModal" width="1000" :mask-closable="false" :footer-hide ='true'>
            <p slot="header" style="text-align:center">
                <span>报价明细</span>
            </p>
            <div class="">                
                <Table :columns="modalTableColumns" @on-sort-change='changeSort' :data="modaltableData" :loading='loadingTTable'>
                    <template slot-scope="{ index }" slot="index">
                        <strong>{{ index+1 }}</strong>
                    </template>  
                    <template slot-scope="{ row }" slot="action">
                        <Button @click="enter_export(row)" style="color:green;background: unset;" type="text">下载</Button>
                    </template>
                </Table>                
                <div style="margin-top:10px">
                <Page :total="tableTotal" :current="pageTableForm.page" @on-change="pageTableChange" show-total show-elevator style="text-align:right" />  </div>
            </div>
        </Modal>
    </div>
</template>

<script>
    //上传图片√  图片查看√   导入 导出√  查询√   下拉框√   增√ 删√ 改√ 查√  表格套表格√     下拉框√   输入框√  数字框√ 日期选择√
    // http://192.168.1.199:93/admin/active/create
    import {selectMaterial ,getActiveList,active_create,active_delete,enter_export,get_enter_list,active_export,recommend_export,unit_recommend_export } from "@/api/index";
    // getActiveList,active_create,active_delete,active_create,
    import config from '@/config';
    import { getCookies } from '@/utils/helper'
    export default {
        data(){
            return{
                pageTittle: '报价活动',     //页面标题 eg:人员列表 订单列表  系统设置 
                pageContent: '页面内容',    //页面内容
                pageText: '页面文本',       //页面文本
                pageTheme: '报价活动',      //页面主题  eg:人员 订单  系统设置
                addButtonText: '新增',  //新增按钮文本
                editButtonText: '修改',  //新增按钮文本      
                timeVal: [],
                timeModalVal: [],
                loadingTable:false,  //表格loading
                loadingTTable:false,
                dataTable: [{},{}],    //表格data
                searchData: {
                    keywords: '',
                    material_id: '',
                    // seachThree: '',
                    // seachFour: '',
                },                
                seachListArr:[
                ],
                //上传头
                uploadHeader:{
                    'authori-zation-export':getCookies('token')
                },    
                 //上传携带的form data  
                uploadData: {
                    id:'',
                },          
                imageConfig:config.apiUrl,
                uploadsAction: config.apiUrl + '/adminapi/user/upload',
                //表格columns
                columnsTable: [
                    // {type: 'selection',width: 60,align: 'center'},
                    {title: '#',width: 60,slot: 'index',align: 'center',},
                    {title: '标题',key: 'name',align: 'center',},
                    {title: '所属物料',slot: 'material',align: 'center',},
                    {title: '起止时间',slot: 'time',align: 'center',},       
                    {title: '二维码',slot: 'tablePhoto',align: 'center',},                                 
                    {title: '报价明细',slot: 'tableTable',align: 'center',},                                          
                    {title: '操作',slot: 'action',width: 420,fixed: 'right',align: 'center',}
                ],
                total: 0,   //表格数据总数
                tableTotal: 0,
                imgUrl: 'https://i.loli.net/2017/08/21/599a521472424.jpg',
                //表格分页form
                pageForm: {
                    page:1,
                    limit:10
                },
                pageTableForm: {
                    page:1,
                    limit:10,
                    active_id:''
                },
                cityList: [],                             
                formData: {
                    id:'',
                    name: '',
                    material_id: '', 
                    start_time: '',
                    end_time: '',
                },
                // 弹窗开始
                modalTittle: '弹窗标题',     //弹窗标题
                modalType: 'add',  
                showAddModal: false,   //弹窗显示
                //弹窗结束
                //弹窗表格
                showTableModal: false,
                modaltableData: [{},{}] ,
                modalTableColumns: [
                    // {type: 'selection',width: 60,align: 'center'},
                    {title: '#',slot: 'index',align: 'center',},
                    {title: '供应商',key: 'company',align: 'center',},
                    {title: '联系人',key: 'name',align: 'center',},
                    {title: '联系电话',key: 'phone',align: 'center',},
                    {title: '（报价）总价',key: 'money',align: 'center',sortable:'custom'},                    
                    {title: '操作',slot: 'action',width: 220,fixed: 'right',align: 'center',}
                ],
            }
        },
        created(){
            this.selectMaterial()
            this.getActiveList()         
        },
        computed: {            
        },
        methods:{
            changeSort(e){
                this.pageTableForm.order_key = e.key
                this.pageTableForm.order_order = e.order
                this.get_enter_list()
            },
            active_export(e){
                active_export({id:e.id}).then(res=>{
                    window.open(res.data)                               
                //    this.seachListArr = res.data
                }).catch(err=>{                    
                    console.log('表格err',err)                    
                    this.$Message.error(err.msg)
                })
            },
            // http://form.hulianzaowu.top/admin/active/unit_recommend_export
            // http://form.hulianzaowu.top/admin/active/recommend_export
            recommend_export(e){
                recommend_export({id:e.id}).then(res=>{
                    window.open(res.data)                               
                //    this.seachListArr = res.data
                }).catch(err=>{                    
                    console.log('表格err',err)                    
                    this.$Message.error(err.msg)
                })
            },
            unit_recommend_export(e){
                unit_recommend_export({id:e.id}).then(res=>{
                    window.open(res.data)                               
                //    this.seachListArr = res.data
                }).catch(err=>{                    
                    console.log('表格err',err)                    
                    this.$Message.error(err.msg)
                })
            },
            enter_export(e){
                enter_export({id:e.id}).then(res=>{
                    window.open(res.data)                               
                //    this.seachListArr = res.data
                }).catch(err=>{                    
                    console.log('表格err',err)                    
                    this.$Message.error(err.msg)
                })
            },
            selectMaterial(){
                selectMaterial().then(res=>{
                   this.seachListArr = res.data
                }).catch(err=>{                    
                    console.log('表格err',err)
                })

            },
            get_enter_list(){
                let data = {}
                data = {...this.pageTableForm, }
                this.loadingTTable = true  //数据加载
                get_enter_list(data).then(res=>{
                    this.loadingTTable = false
                    this.tableTotal = res.data.total
                    this.modaltableData = res.data.data
                }).catch(err=>{                    
                    // this.$Message.error(err.msg)
                    console.log('表格err',err)
                })
            },
            goShowTable(e){
                this.pageTableForm.active_id = e.id
                this.pageTableForm.page = 1
                this.get_enter_list()
                this.showTableModal = true
            },
            // 上传开始！！！！
             //文件上传类型错误
             handleFormatError () {
                this.$Message.warning('暂不支持上传此格式');
            },
            //编辑图片上传图片成功
            uploadImgSuccess(e){
                this.formData.imgUrl = e.data.url
                // this.formData.imgUrl = e.data.img
                this.$Message.destroy()
                this.$Message.success(e.msg);
            }, 
            //删除图片
            delavatar(){
                this.formData.imgUrl = ''
            },   
            // 文件上传时
            handleProgress(){
                const msg = this.$Message.loading({
                    content: '上传中...',
                    duration: 0
                });
                setTimeout(msg, 3000);
            },
            // 上传结束！！！！
            goSee(e){
                window.open(e)
            },
            seachListChange(e){
                console.log('选择切换id:',e)
            } , 
            changeTime(e){
                this.searchData.seachThree = e[0]
                this.searchData.seachFour =  e[1]
            },
            changeModalTime(e){
                this.formData.start_time = e[0]
                this.formData.end_time = e [1]
            },
            getActiveList(){
                let data = {}
                data = {...this.pageForm, ...this.searchData }
                this.loadingTable = true  //数据加载
                getActiveList(data).then(res=>{
                    this.loadingTable = false
                    this.total = res.data.total
                    this.dataTable = res.data.data
                }).catch(err=>{                    
                    // this.$Message.error(err.msg)
                    console.log('表格err',err)
                })
            },
             //表格选中
            chooseData(e){
                console.log('e',e)
            },
            //表格开关
            changeSwitch(e){
                console.log('e', e)
            },
            //新增
            goAddData(){
                this.reast()
                this.showAddModal = true
                this.modalType = 'add'
            },
            // 清空表单
            reast(){
                this.formData.id = ''
                this.formData.name = ''
                this.formData.material_id = ''
                this.formData.start_time = ''
                this.formData.end_time = ''
                this.timeModalVal = []
                // this.formData.dataTwo = ''
                // this.formData.dataThree = 0
                // this.formData.dataFive = ''
                // this.formData.imgUrl = ''
            },    
            //关闭弹窗
            close_modal(){
                this.showAddModal = false
                this.reast()
            },    
            // 新增 修改                       
            active_create(){
                if(this.formData.name == ''){
                    this.$Message.error('请输入标题')
                    return
                }
                if(this.formData.material_id == '' ){
                    this.$Message.error('请选择报价物料')
                    return
                }      
                if( !this.formData.start_time  ){
                    this.$Message.error('请选择起止时间')
                    return
                }                
                if(this.modalType == 'add'){
                    //新增
                    active_create(this.formData).then(res=>{
                        this.$Message.success(res.msg)
                        this.getActiveList()
                        this.showAddModal = false
                    }).catch(err=>{
                        this.$Message.error(err.msg)
                    })
                }else{
                    //修改
                    active_create(this.formData).then(res=>{
                        this.$Message.success(res.msg)
                        this.getActiveList()
                        this.showAddModal = false
                    }).catch(err=>{
                        this.$Message.error(err.msg)
                    })
                }                
            },
            goEdit(e){                
                this.reast()                
                this.formData.name = e.name
                // this.formData.dataTwo = e.dataTwo
                // this.formData.dataThree = e.dataThree
                this.formData.material_id = e.material_id
                // this.formData.dataFive = e.dataFive
                this.formData.start_time = e.start_time
                this.formData.end_time = e.end_time
                this.timeModalVal = []
                this.timeModalVal.push(e.start_time)
                this.timeModalVal.push(e.end_time)
                // this.formData.imgUrl = e.imgUrl
                this.formData.id = e.id
                this.showAddModal = true               
                this.modalType = 'edit'
            },  
            active_delete(e){
                this.$Modal.confirm({
                title: '提示',
                content: `是否确认删除该` + this.pageTheme,
                onOk: () => {
                    active_delete({ id:e.id }).then(res=>{
                        this.$Message.success(res.msg)
                        this.getActiveList()
                    }).catch(err=>{
                        this.$Message.error(err.msg)
                        this.get_list()
                    })
                },
                onCancel: () => {
                }
                });
            }, 
            goSearch(){
                this.pageForm.page = 1
                this.getActiveList()
            }, 
            //导出
            goExport(){            
                return
                let a = config.apiUrl + '/adminapi/user/export_invoice?is_export=1&region_id' + this.searchData.region_id+'&store_id='+this.searchData.store_id+'&type='+this.searchData.type
                console.log('a',a)
                window.location.href = a
            },  
            //分页切换                
            pageChange(e){
                this.pageForm.page = e
                this.getActiveList()
            },
            pageTableChange(e){
                this.pageTableForm.page = e
                this.get_enter_list()
            },
        }
    }
</script>  

<style scoped>
.new_content {
    /* width: 100%; */
    /* height: calc(100vh - 104px); */
    overflow: auto;
    /* padding: 20px 40px; */
    padding: 24px;
    /* background: #FFF; */
    border-radius: 8px;
    box-shadow: #F4F0E8 0px 1px 2px 0px;
}
.cont_tab {
    padding: 0px 0;
}
.flex_sta_cen {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.search_box {
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 20px;
}
.tab_tab_text {
    /* width: 0px; */
    padding: 4px 16px;
    border-radius: 4px;
    border: 1px solid #002FA7;
    cursor: pointer;
    color: #002FA7;
}
.table_img_box {
    /* width: 60px;
    height: 60px; */
}
.table_img_box img {
    width: 80px;
    height: 80px;
}
/* 上传样式开始 */
.upload_img{
    cursor: pointer;
    width: 80px;
    height: 80px;
    border: 1px solid #eee;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.upload_img_txt{
    margin-top: -10px;
    font-size: 12px;
    color: #999;
}
.absolu_close:hover{
    opacity: 1;
}
.absolu_close {
    cursor: pointer;
    position: absolute;
    top: 0;
    opacity: 0;
    left: 0;
    width: 80px;
    height: 80px;
    background: rgba(0,0,0,.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.viewimg{
    position: relative;
    width: 80px;
    height: 80px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
}
.viewimg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* 上传样式结束 */

</style>